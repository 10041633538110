import React, { useState } from "react";
import {
  Button,
  Form,
  Space,
  message,
} from 'antd';
import { useGetUserQuery, useUpdateMemberMutation } from "../features/api/userAPI";
import { getRenderWidget, readonlyFields } from "./utils";
import ContentWrap from "../components/ContentHead";

function Home() {
  const { data } = useGetUserQuery()
  const [editMode, setEditMode] = useState(false)
  const [updateMember, { isLoading: updateIsLoading }] = useUpdateMemberMutation()
  const fields = data?.fields
  const onFinish = (values) => {
    updateMember({ values }).then((result) => {
      const status = result?.error?.status
      setEditMode(false)
      if (status === 200) {
        message.success("修改成功")
      } else if (status === 401) {
        message.error("登录超时，请重新登录")
      }
    })
  };

  return (
    <>
      <ContentWrap breadItems={[{ title: "企业信息" }, { title: "基本信息" }]}>
        <div className="relative">
          {!editMode && (
            <Button onClick={() => { setEditMode(true) }} type="primary button1" className="absolute right-3" >修改信息</Button>
          )}
        </div>

        <Form
          className="mt-2"
          name="validate_other"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          onFinish={onFinish}
          style={{
            maxWidth: 800,
          }}
        >
          {fields?.map((obj, index) => {
            const label = obj?.label
            const value = obj?.value
            const name = obj?.name
            const readonly = obj?.readonly
            const widgetName = obj?.widget
            const choices = obj?.choices
            const hidden = obj?.hidden
            const valueDisplay = obj?.value_display
            const replacedValueDisplay = readonlyFields(valueDisplay)

            if (!editMode || readonly) {
              return (
                <Form.Item className="" hidden={hidden} label={label} key={index}>
                  <div className="ant-form-text">{replacedValueDisplay}</div>
                </Form.Item>
              )
            } else {
              const widget = getRenderWidget({ widgetName, valueDisplay, choices, value })
              return (
                <Form.Item hidden={hidden} initialValue={value} name={name} label={label} key={index}>
                  {widget}
                </Form.Item>
              )
            }
          })}
          {editMode &&
            <Form.Item
              wrapperCol={{
                span: 12,
                offset: 4,
              }}
            >
              <Space size={"middle"}>
                <Button loading={updateIsLoading} type="primary" htmlType="submit">
                  提交
                </Button>
                <Button loading={updateIsLoading} onClick={() => { setEditMode(false) }}>取消</Button>
              </Space>
            </Form.Item>}
        </Form >
      </ContentWrap>
    </>
  )
}

export default Home
