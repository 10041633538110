import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { BASE_URL, useUpdateExcellentProjectmanagerAdwardMutation } from "../features/api/userAPI";

const ProjectQuanlityAttached = ({ title, refs_id, id, model_type, attached, requiredRule, restField }) => {
  const [updateAdvancedEnterpriseAdward] = useUpdateExcellentProjectmanagerAdwardMutation()
  const fileName = attached?.split("/").pop()
  const originalFileList = attached ? [{
    uid: refs_id,
    url: attached,
    name: decodeURIComponent(fileName),
    status: "done",
  }] : null
  const [fileList, setFileList] = useState(originalFileList)
  const [mRuler, setMRuler] = useState(false)
  useEffect(() => {
    if (fileList?.length > 0) {
      setMRuler(false)
    } else {
      if (requiredRule.required) {
        setMRuler(true)
      } else {
        setMRuler(false)
      }
    }
  }, [requiredRule, fileList])


  const [messageApi, contextHolder] = message.useMessage();
  const uploadOrClearAttached = ({ formData }) => {
    updateAdvancedEnterpriseAdward({ payload: formData, id }).then((result) => {
      const rst = result?.data?.result
      const errorMessage = result?.data?.message
      const url = `${BASE_URL}${result?.data?.url}`
      const filename = result?.data?.filename
      if (rst !== "ok") {
        messageApi.error(`保存失败: ${errorMessage}`)
      } else {
        if (url) {
          setFileList([{
            uid: refs_id,
            url: url,
            name: filename,
            status: 'done',
          }])
        } else {
          setFileList(null)
        }

      }
    })

  }
  return (
    <>
      {contextHolder}
      <Form.Item
        style={{ margin: 0 }}
        {...restField}
        rules={[{
          required: mRuler,
          message: '请上传附件'
        }]}
        valuePropName="fileList"
        getValueProps={(value) => {
          return value
        }}
        getValueFromEvent={(value) => {

          const fileList = value?.fileList
          let rst = undefined
          if (fileList && fileList.length > 0) {
            rst = fileList
          }
          // console.log("@664", rst);
          return rst
        }}
        name={"nonusefield"}>
        <Upload
          maxCount={1}
          fileList={fileList}
          accept="application/pdf,image/png,image/jpeg"
          customRequest={({ file }) => {
            const formData = new FormData()
            formData.append("attached", file)
            formData.append("refs_id", refs_id)
            formData.append("model_type", model_type)
            uploadOrClearAttached({ formData })
          }}
          onRemove={() => {
            const formData = new FormData()
            formData.append("attached", '')
            formData.append("refs_id", refs_id)
            formData.append("model_type", model_type)
            uploadOrClearAttached({ formData })
          }}
        >
          {!fileList &&
            <Button icon={<UploadOutlined />}>{title}</Button>
          }
        </Upload>
      </Form.Item>
    </>
  )
}

export default ProjectQuanlityAttached
