import { Input, Space, Button, Form, message, Row, Col, Divider, Typography, Popconfirm, Select, Upload } from "antd";
import React, { useState } from "react";
import { useGetAdvancedEnterpriseDetailQuery, useUpdateAdvancedEnterpriseMutation, useGetMainprojectLevelTypeQuery, useUpdateAdvancedEnterpriseAttachedMutation, BASE_URL, useUpdateAdvancedEnterpriseAdwardMutation } from "../../features/api/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ContentWrap from "../../components/ContentHead";
import SuccessfulEntrepreneurAdward from "../../components/SuccessfulEntrepreneurAdward";
const { Title } = Typography
const AdvancedEnterpriseAdd = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, isLoading } = useGetAdvancedEnterpriseDetailQuery({ id })

  const [form] = useForm()
  const [updateAdvancedEnterprise, { isLoading: updateIsLoading }] = useUpdateAdvancedEnterpriseMutation()
  const [updateAdvancedEnterpriseAttached] = useUpdateAdvancedEnterpriseAttachedMutation()

  const { data: mainProjectData, isLoading: getMainProjectLoading } = useGetMainprojectLevelTypeQuery()
  const options = mainProjectData?.results
  const [fileList, setFileList] = useState([])
  const [requiredRule, setRequiredRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredFileRule, setRequiredFileRule] = useState({ required: true, message: "这个字段是必须的" })
  useEffect(() => {
    const attached = data?.attached
    if (attached) {
      const fileList = [{ uid: -1, name: data.attached_name, status: 'done', url: attached }]
      setFileList(fileList);
    }
  }, [data]);
  const breadItmes = [
    { title: "评优评先" },
    { title: "先进企业", path: "/advancedenterprise" },
    { title: "修改" }
  ]

  const navigate_list = () => {
    if (form.getFieldValue("status") !== 1) {
      setRequiredRule({ ...requiredRule, required: true })
      if (fileList && fileList.length > 0) {
        setRequiredFileRule({ ...requiredFileRule, required: false })
      } else {
        setRequiredFileRule({ ...requiredFileRule, required: true })
      }
    } else {
      navigate("/advancedenterprise", { replace: true })
    }
  }
  const onFinish = (values) => {

    const payload = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    );
    console.log("@53", payload);
    updateAdvancedEnterprise({ payload, id }).then((result) => {
      const _id = result?.data?.id
      if (_id) {
        message.success("保存成功")
        navigate_list()
      } else {
        message.error("登录超时，请重新登录")
      }
    })
  };
  const handleUploadChange = (info) => {
    setFileList([...info.fileList]);
  }
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const addOrClearAttached = ({ formData }) => {
    updateAdvancedEnterpriseAttached({ payload: formData, id }).then((result) => {
      const rst = result?.data?.result
      const errorMessage = result?.data?.message
      const url = `${BASE_URL}${result?.data?.url}`
      const filename = result?.data?.filename
      if (rst !== "ok") {
        message.error(`保存失败: ${errorMessage}`)
      } else {
        if (result?.data?.url) {
          setFileList([{
            uid: url,
            url: url,
            name: filename,
            status: 'done',
          }])
        } else {
          setFileList(null)
        }
      }
    })
  }

  const companyName = data?.name
  const title = "先进企业申请表"
  const memberID = data?.member
  const status = data?.status
  const addressWork = data?.address_work
  const certLevel = data?.cert_level
  const year = data?.year
  return (
    <ContentWrap breadItems={breadItmes}>
      {isLoading || getMainProjectLoading ?
        <LoadingOutlined /> :
        <Form
          form={form}
          className="mt-10 ml-20"
          onFinish={onFinish}
          scrollToFirstError
          style={{ maxWidth: 800 }}
        >
          <Form.Item hidden name={"year"} initialValue={year} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"id"} initialValue={data?.id} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"member"} initialValue={memberID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"status"} initialValue={status} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Title className="text-center" level={3}>{title}</Title>
          <Divider></Divider>
          <Row gutter={[16, 0]}>
            <Col span={11}>
              <Form.Item
                name={"name"}
                label="企业名称"
                initialValue={companyName}
                rules={[requiredRule]}
              >
                <Input readOnly type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item
                name={"cert_level"}
                label="企业资质"
                initialValue={certLevel}
                rules={[{ required: true, message: "这个字段是必须的" }]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  optionFilterProp="children"
                  options={options}
                  placeholder="请选择企业资质"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                initialValue={data?.application_type}
                name={"application_type"}
                label="企业分类"
                rules={[requiredRule]}
              >
                <Select
                  options={[
                    { label: "建筑业", value: "建筑业" },
                    { label: "交通", value: "交通" },
                    { label: "水利", value: "水利" },
                    { label: "电力", value: "电力" },
                  ]}
                  placeholder="请选择企业类型" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={addressWork}
                name={"address_work"}
                label="通讯地址"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.zipcode}
                name={"zipcode"}
                label="邮编"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                initialValue={data?.boss}
                name={"boss"}
                label="法人代表"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                initialValue={data?.boss_phone}
                name={"boss_phone"}
                label="联系电话"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.contract}
                name={"contract"}
                label="&emsp;联系人"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.position}
                name={"position"}
                label="职&emsp;&emsp;务"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.contract_phone}
                name={"contract_phone"}
                label="联系电话"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left"></Divider>
          <Row className="mb-5" gutter={[6]}>
            <Col span={2}><b>序号</b></Col>
            <Col span={6}><b>项目</b></Col>
            <Col span={2}><b>单位</b></Col>
            <Col span={5}><b>{year - 1}</b></Col>
            <Col span={5}><b>{year}</b></Col>
            <Col span={4}><b>备注</b></Col>
          </Row>
          <Row gutter={[6, 0]} className="mb-9">
            {/* start loop */}
            <Col span={2}>1</Col>
            <Col span={6}>企业净资产</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item1_last_year}
                name={"item1_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item1_this_year}
                name={"item1_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item1_remark}
                name={"item1_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}

            {/* start loop */}
            <Col span={2}>2</Col>
            <Col span={6}>资产负债率</Col>
            <Col span={2}>%</Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item2_last_year}
                name={"item2_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item2_this_year}
                name={"item2_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item2_remark}
                name={"item2_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>3</Col>
            <Col span={6}>建筑业总产值</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_last_year}
                name={"item3_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_this_year}
                name={"item3_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item3_remark}
                name={"item3_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>4</Col>
            <Col span={6}>其中：省外产值</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_last_year}
                name={"item4_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_this_year}
                name={"item4_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item4_remark}
                name={"item4_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>5</Col>
            <Col span={6}>营业收入</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_last_year"}
                initialValue={data?.item5_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_this_year"}
                initialValue={data?.item5_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item5_remark"}
                initialValue={data?.item5_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>6</Col>
            <Col span={6}>上缴税收</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_last_year"}
                initialValue={data?.item6_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_this_year"}
                initialValue={data?.item6_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item6_remark"}
                initialValue={data?.item6_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>7</Col>
            <Col span={6}>实现利润</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item7_last_year"}
                initialValue={data?.item7_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item7_this_year"}
                initialValue={data?.item7_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item7_remark"}
                initialValue={data?.item7_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>8</Col>
            <Col span={6}>科研经费投入</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item8_last_year"}
                initialValue={data?.item8_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item8_this_year"}
                initialValue={data?.item8_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item8_remark"}
                initialValue={data?.item8_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>9</Col>
            <Col span={6}>年末从业人员</Col>
            <Col span={2}>人</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item9_last_year"}
                initialValue={data?.item9_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item9_this_year"}
                initialValue={data?.item9_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item9_remark"}
                initialValue={data?.item9_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            <Col span={24}>
              <b>注：1、资产负债率=负债总额/资产总额*100%</b>
            </Col>
          </Row>
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="advancedenterprise"
            model_type={0}
            requiredRule={requiredRule}
            listTitle={`${data?.year}年获得县级以上政府或部门奖项名称`}
            listName="ministry_awards"
            adwards={data?.ministry_awards}
            updateFun={useUpdateAdvancedEnterpriseAdwardMutation}
          />
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="advancedenterprise"
            model_type={1}
            requiredRule={requiredRule}
            listTitle={`${data?.year}年质量安全奖项`}
            listName="quality_awards"
            adwards={data?.quality_awards}
            updateFun={useUpdateAdvancedEnterpriseAdwardMutation}
            tips="填写本年度获得的项目最高质量安全奖项" />
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="advancedenterprise"
            model_type={2}
            requiredRule={requiredRule}
            listTitle={`${data?.year}年科技创新奖项`}
            listName="science_awards"
            updateFun={useUpdateAdvancedEnterpriseAdwardMutation}
            adwards={data?.science_awards} />
          <Divider orientation="left"></Divider>

          <Form.Item
            rules={[requiredRule]}
            name={"major_performance_enterprise"}
            initialValue={data?.major_performance_enterprise}
            labelCol={{
              span: 24,
            }}
            label={<b>企业主要业绩</b>}>
            <TextArea autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            rules={[requiredRule]}
            initialValue={data?.enterprise_opinion}
            labelCol={{
              span: 24,
            }}
            name={"enterprise_opinion"}
            label={<b>企业意见</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            name="tempUploadFile"
            label="申报材料"
            extra="市建筑业先进企业申报资料内容：1、企业三证（营业执照副本、资质证书副本、安全生产许可证）；2、申报企业近二年缴纳协会会费发票；3、企业当年统计年报；4、其他相关资料。以上申报资料请汇总成一份PDF文件上传。"
            rules={[requiredFileRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              // console.log("@664", rst);
              return rst
            }}
          >
            <Upload
              accept="application/pdf"
              customRequest={({ file }) => {
                const formData = new FormData()
                formData.append("attached", file)
                formData.append("year", year)
                addOrClearAttached({ formData })
              }}
              onRemove={() => {
                const formData = new FormData()
                formData.append("attached", '')
                formData.append("year", year)
                addOrClearAttached({ formData })
              }}
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={(file) => {
                const isPDF = file?.type === "application/pdf";
                if (!isPDF) {
                  message.error(`${file.name} 不是PDF文件`)
                  return Upload.LIST_IGNORE
                }
              }}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          {data.status === 0 &&
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              className="text-center"
            >
              <Space size={"large"}>
                <Button size="large" onClick={() => {
                  setRequiredRule({ ...requiredRule, required: false })
                  setRequiredFileRule({ ...requiredFileRule, required: false })
                  form.setFieldValue("status", 0)
                  setTimeout(() => {
                    form.submit()
                  }, 100)
                  // form.submit()
                }} loading={updateIsLoading} >
                  保存
                </Button>
                <Popconfirm
                  title="提醒"
                  description="提交后表单无法修改，您确认要提交吗？"
                  onConfirm={() => {
                    setRequiredRule({ ...requiredRule, required: true })
                    if (fileList && fileList.length > 0) {
                      setRequiredFileRule({ ...requiredFileRule, required: false })
                    }
                    form.setFieldValue("status", 1)
                    setTimeout(() => {
                      form.submit()
                    }, 100)
                    // form.submit()
                  }}
                  okText="确定"
                  cancelText="取消"
                >

                  <Button size="large" loading={updateIsLoading} type="primary">提交</Button>
                </Popconfirm>
              </Space>
            </Form.Item>
          }
        </Form >
      }
    </ContentWrap>
  )
}

export default AdvancedEnterpriseAdd
