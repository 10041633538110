import { Input, Space, Button, Form, message, Row, Col, Divider, Typography, Popconfirm, Select, Upload, DatePicker } from "antd";
import React, { useState } from "react";
import { useGetExcellentProjectmanagerDetailQuery, useUpdateExcellentProjectmanagerMutation, useUpdateExcellentProjectmanagerAttachedMutation, BASE_URL, useUpdateExcellentProjectmanagerAdwardMutation } from "../../features/api/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ContentWrap from "../../components/ContentHead";
import dayjs from 'dayjs';
import SuccessfulEntrepreneurAdward from "../../components/SuccessfulEntrepreneurAdward";
import ProjectQuanlity from "../../components/ProjectQuanlity";
const { Title } = Typography
const ExcellentProjectmanagerAdd = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, isLoading } = useGetExcellentProjectmanagerDetailQuery({ id })

  const [form] = useForm()
  const [updateAttachedFun] = useUpdateExcellentProjectmanagerAttachedMutation()
  const [updateExcellentProjectmanager, { isLoading: updateIsLoading }] = useUpdateExcellentProjectmanagerMutation()
  const [fileList, setFileList] = useState([])
  const [photoList, setPhotoList] = useState([])
  const [trainingList, setTrainingList] = useState([])
  const STATUS_ATTACHED = 1
  const STATUS_PHOTO = 2
  const STATUS_TRAINING = 3
  const [requiredRule, setRequiredRule] = useState({ required: true, message: "这个字段是必须的" })
  const [mustRequiredRule, setMustRequiredRule] = useState({ required: true, message: " " })

  const [requiredFileRule, setRequiredFileRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredPhotoRule, setRequiredPhotoRule] = useState({ required: true, message: "这个字段是必须的" })

  useEffect(() => {
    const attached = data?.attached
    const photo = data?.photo
    const trainingFile = data?.training_approval_file
    const defaultList = {
      uid: -1,
      status: "done"
    }
    if (attached) {
      setFileList([{
        name: data.attached_name,
        url: attached,
        ...defaultList
      }]);
    }
    if (photo) {
      setPhotoList([{
        name: data?.photo_name,
        url: photo,
        ...defaultList
      }]);
    }
    if (trainingFile) {
      setTrainingList([{
        name: data?.training_approval_filename,
        url: trainingFile,
        ...defaultList
      }]);
    }

  }, [data]);
  const breadItmes = [
    { title: "评优评先" },
    { title: "优秀项目经理（建造师）", path: "/excellentprojectmanager" },
    { title: "修改" }
  ]
  const setAttachedPhotoRequired = () => {
    if (fileList && fileList.length > 0) {
      setRequiredFileRule({ ...requiredFileRule, required: false })
    } else {
      setRequiredFileRule({ ...requiredFileRule, required: true })
    }
    if (photoList && photoList.length > 0) {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
    } else {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: true })
    }
  }
  const navigate_list = () => {
    if (form.getFieldValue("status") !== 1) {
      setRequiredRule({ ...requiredRule, required: true })
      setAttachedPhotoRequired()
    } else {
      navigate("/excellentprojectmanager", { replace: true })
    }
  }



  const onFinish = (values) => {

    const payload = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    );

    updateExcellentProjectmanager({ payload, id }).then((result) => {
      const _id = result?.data?.id
      if (_id) {
        message.success("保存成功")
        navigate_list()
      } else {
        message.error("登录超时，请重新登录")
      }
    })
  };

  const companyName = data?.company_name
  const title = "优秀项目经理（建造师）申请表"
  const mID = data?.id
  const memberID = data?.member
  const status = data?.status
  const addressWork = data?.company_address
  const year = data?.year
  const project_years = data?.project_years || null
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传图片
      </div>
    </div>
  )

  const addOrClearAttached = ({ formData, setFun }) => {
    updateAttachedFun({ payload: formData, id }).then((result) => {
      const rst = result?.data?.result
      const errorMessage = result?.data?.message
      const url = `${BASE_URL}${result?.data?.url}`
      const filename = result?.data?.filename
      if (rst !== "ok") {
        message.error(`保存失败: ${errorMessage}`)
      } else {
        if (result?.data?.url) {
          setFun([{
            uid: url,
            url: url,
            name: filename,
            status: 'done',
          }])
        } else {
          setFun(null)
        }
      }
    })
  }
  return (
    <ContentWrap breadItems={breadItmes}>
      {isLoading ?
        <LoadingOutlined /> :
        <Form
          form={form}
          className="mt-10 ml-20"
          onFinish={onFinish}
          scrollToFirstError
          style={{ maxWidth: 800 }}
        >
          <Form.Item hidden name={"year"} initialValue={year} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"id"} initialValue={mID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"member"} initialValue={memberID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"status"} initialValue={status} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Title className="text-center" level={3}>{title}</Title>
          <Divider></Divider>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name={"name"}
                label="姓&emsp;&emsp;名"
                initialValue={data?.name}
                rules={[{ required: true, message: "这个字段是必须的" }]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"sex"}
                label="性别"
                initialValue={data?.sex}
                rules={[requiredRule]}
              >
                <Select
                  options={[{ value: "男", label: "男" }, { value: "女", label: "女" }]}
                  placeholder="请选择性别"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.birthday || ""}
                getValueFromEvent={(...[, dateString]) => dateString}
                getValueProps={(value) => ({
                  value: value ? dayjs(value, "YYYY-MM-DD") : "",
                })}
                name={"birthday"}
                label="出生年月"
                rules={[requiredRule]}
              >
                <DatePicker format={"YYYY-MM-DD"} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.ethnicity}
                name={"ethnicity"}
                label="民族"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.education}
                name={"education"}
                label="文化程度"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.job_title}
                name={"job_title"}
                label="职称"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.political_background}
                name={"political_background"}
                label="政治面貌"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.position}
                name={"position"}
                label="职务"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.construction_cert_level}
                name={"construction_cert_level"}
                label="建造师资质等级"
                rules={[requiredRule]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={[
                    { value: "建筑一级", label: "建筑一级" },
                    { value: "建筑二级", label: "建筑二级" },
                    { value: "公路一级", label: "公路一级" },
                    { value: "公路二级", label: "公路二级" },
                    { value: "水利水电一级", label: "水利水电一级" },
                    { value: "水利水电二级", label: "水利水电二级" },
                    { value: "市政一级", label: "市政一级" },
                    { value: "市政二级", label: "市政二级" },
                    { value: "机电一级", label: "机电一级" },
                    { value: "机电二级", label: "机电二级" },
                    { value: "矿业一级", label: "矿业一级" },
                    { value: "矿业二级", label: "矿业二级" },
                    { value: "铁路一级", label: "铁路一级" },
                    { value: "民航机场一级", label: "民航机场一级" },
                    { value: "港口与航道一级", label: "港口与航道一级" },
                    { value: "通信与广电一级", label: "通信与广电一级" },
                  ]}
                  placeholder="请选择建造师资质等级"
                />
                {/* <Input type="text1" className="ant-form-text" /> */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.construction_cert_number}
                name={"construction_cert_number"}
                label="资质证书编号"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.application_type}
                name={"application_type"}
                label="企业分类"
                rules={[requiredRule]}
              >
                <Select
                  options={[
                    { label: "建筑业", value: "建筑业" },
                    { label: "交通", value: "交通" },
                    { label: "水利", value: "水利" },
                    { label: "电力", value: "电力" },
                  ]}
                  placeholder="请选择企业类型" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.id_card}
                name={"id_card"}
                label="身份证号码"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.phone}
                name={"phone"}
                label="联系电话"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                initialValue={companyName}
                name={"company_name"}
                label="工作单位"
                rules={[requiredRule]}
              >
                <Input type="text1" readOnly className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={addressWork}
                name={"company_address"}
                label="工作单位地址"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.work_time}
                name={"work_time"}
                extra="以年为单位"
                label="担任项目经理时间"
                rules={[requiredRule]}
              >
                <Input type="text1"
                  addonAfter="年"
                  className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.zipcode}
                name={"zipcode"}
                label="邮政编码"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item
                initialValue={data?.contract}
                name={"contract"}
                label="联系人姓名"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.contract_phone}
                name={"contract_phone"}
                label="联系人手机"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>

            {/* textarea */}
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"training_class"}
                initialValue={data?.training_class}
                label="当年参加过何种项目经理业务培训班">
                <TextArea autoSize={{ minRows: 4 }} ></TextArea>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            // name="training_approval_file"
            label="培训证明文件"
            extra="如无则不需要上传"
          >
            <Upload
              name={"nonusefield"}
              valuePropName="fileList"
              getValueProps={(value) => {
                return value
              }}
              getValueFromEvent={(value) => {

                const fileList = value?.fileList
                let rst = undefined
                if (fileList && fileList.length > 0) {
                  rst = fileList
                }
                // console.log("@664", rst);
                return rst
              }}
              accept="image/png,image/jpeg,application/pdf"
              customRequest={({ file }) => {
                const formData = new FormData()
                formData.append("attached", file)
                formData.append("year", year)
                formData.append("type", STATUS_TRAINING)
                addOrClearAttached({ formData, setFun: setTrainingList })
              }}
              onRemove={() => {
                const formData = new FormData()
                formData.append("attached", '')
                formData.append("year", year)
                formData.append("type", STATUS_TRAINING)
                addOrClearAttached({ formData, setFun: setTrainingList })
              }}
              fileList={trainingList}
              maxCount={1}
              listType="picture"
              showUploadList={{ showPreviewIcon: false }}
            >
              {trainingList && trainingList.length >= 1 ? null : <Button icon={<UploadOutlined />}>上传材料</Button>}
            </Upload>
          </Form.Item>
          <ProjectQuanlity
            project_years={project_years}
            year={year}
            mID={mID}
            mustRequiredRule={mustRequiredRule}
          />
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="excellentprojectmanager"
            model_type={0}
            requiredRule={requiredRule}
            listTitle={`${data?.year}年获得质量安全奖项名称`}
            listName="quality_awards"
            adwards={data?.quality_awards}
            updateFun={useUpdateExcellentProjectmanagerAdwardMutation}
          />
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="excellentprojectmanager"
            model_type={1}
            requiredRule={requiredRule}
            listTitle={`${data?.year}年获得其他荣誉名称`}
            listName="honor_awards"
            adwards={data?.honor_awards}
            updateFun={useUpdateExcellentProjectmanagerAdwardMutation}
          />
          <Divider orientation="left">工程项目管理先进经验和先进事迹</Divider>
          <Row>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"advanced_experience"}
                initialValue={data?.advanced_experience}
                label={`工程项目管理先进经验和先进事迹（500字左右）：`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            rules={[requiredRule]}
            initialValue={data?.enterprise_opinion || "同意上报"}
            labelCol={{
              span: 24,
            }}
            name={"enterprise_opinion"}
            label={<b>企业意见</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            name={"tempPhotoList"}
            label="电子照片"
            extra="请使用1寸照片，宽：300px，高：450px"
            rules={[requiredPhotoRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              // console.log("@664", rst);
              return rst
            }}
          >
            <Upload
              accept="image/png,image/jpeg"
              customRequest={({ file }) => {
                const formData = new FormData()
                formData.append("attached", file)
                formData.append("year", year)
                formData.append("type", STATUS_PHOTO)
                addOrClearAttached({ formData, setFun: setPhotoList })
              }}
              onRemove={() => {
                const formData = new FormData()
                formData.append("attached", '')
                formData.append("year", year)
                formData.append("type", STATUS_PHOTO)
                addOrClearAttached({ formData, setFun: setPhotoList })
              }}
              fileList={photoList}
              maxCount={1}
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
            >
              {photoList && photoList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            name="tempUploadFile"
            label="申报材料"
            extra="市建筑业优秀项目经理（建造师）申报资料内容：1、项目经理（建造师）注册证书（园林工程师证）；2、身份证正、反面；3、申报企业近二年缴纳协会会费发票；4、任职项目经理（建造师）三年以上的证明（任职、任命文件）。以上申报资料请汇总成一份PDF文件上传。"
            rules={[requiredFileRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              return rst
            }}
          >
            <Upload
              name="tempUploadFile1"
              accept="application/pdf"
              customRequest={({ file }) => {
                const formData = new FormData()
                formData.append("attached", file)
                formData.append("year", year)
                formData.append("type", STATUS_ATTACHED)
                addOrClearAttached({ formData, setFun: setFileList })
              }}
              onRemove={() => {
                const formData = new FormData()
                formData.append("attached", '')
                formData.append("year", year)
                formData.append("type", STATUS_ATTACHED)
                addOrClearAttached({ formData, setFun: setFileList })
              }}
              fileList={fileList}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          {data.status === 0 &&
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              className="text-center"
            >
              <Space size={"large"}>
                <Button size="large" onClick={() => {
                  setRequiredRule({ ...requiredRule, required: false })
                  setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
                  setRequiredFileRule({ ...requiredFileRule, required: false })
                  setMustRequiredRule({ ...mustRequiredRule, required: false })
                  form.setFieldValue("status", 0)
                  setTimeout(() => {
                    form.submit()
                  }, 100);
                }} loading={updateIsLoading} >
                  保存
                </Button>
                <Popconfirm
                  title="提醒"
                  description="提交后表单无法修改，您确认要提交吗？"
                  onConfirm={() => {
                    setAttachedPhotoRequired()
                    setMustRequiredRule({ ...mustRequiredRule, required: true })
                    form.setFieldValue("status", 1)
                    setTimeout(() => {
                      form.submit()
                    }, 100);
                  }}
                  okText="确定"
                  cancelText="取消"
                >

                  <Button size="large" loading={updateIsLoading} type="primary">提交</Button>
                </Popconfirm>
              </Space>
            </Form.Item>
          }
        </Form >
      }
    </ContentWrap >
  )
}

export default ExcellentProjectmanagerAdd
