import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputNumber, Row, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import ProjectQuanlityAttached from "./ProjectQuanlityAttached";
import { getUuid } from "../container/utils";
const ProjectQuanlity = ({
  year, project_years, mID, mustRequiredRule,
}) => {

  const [uuidList, setUuidList] = useState(project_years.map(item => item.refs_id))
  let init_project_years = project_years
  if (!project_years || (project_years && project_years.length === 0)) {
    init_project_years = null
  }

  const formListSpans = [1, 22, 1]
  const formListSpansSub = [5, 4, 3, 3, 3, 3, 3]
  const [totalThisYearPrice, setTotalThisYearPrice] = useState(0)
  const form = Form.useFormInstance()
  const onThisYearPriceChange = () => {
    const projectYears = form.getFieldValue("project_years")
    const temp = projectYears.reduce((sum, dict) => sum + (parseFloat(dict.price_this_year) || 0), 0)
    setTotalThisYearPrice(temp)
  }
  useEffect(() => {
    if (project_years) {
      const temp = project_years.reduce((sum, dict) => sum + (parseFloat(dict.price_this_year) || 0), 0)
      setTotalThisYearPrice(temp)
    }

  }, [project_years])

  return (
    <>
      <Divider orientation="left">{year}年工程项目一览表</Divider>
      <Row className="mb-5" align="middle" gutter={[16, 2]}>
        <Col className="text-center" span={formListSpans[0]}>#</Col>
        <Col span={formListSpans[1]}>
          <Row align="middle" gutter={[16, 4]}>
            <Col span={formListSpansSub[0]}>项目名称</Col>
            <Col span={formListSpansSub[1]}>建筑面积(<b>m<sup>2</sup></b>)</Col>
            <Col span={formListSpansSub[2]}>合同（决算）造价：<b>万元</b>(保留两位小数)</Col>
            <Col span={formListSpansSub[3]}>工程开工、完工时间</Col>
            <Col span={formListSpansSub[4]}>当年完成该项目的工作量 <b>万元</b>(保留两位小数)</Col>
            <Col span={formListSpansSub[5]}>当年获得质量、安全最高奖项名称</Col>
            <Col span={formListSpansSub[6]}>安全情况</Col>
          </Row>
        </Col>
        <Col span={formListSpans[2]}>&nbsp;</Col>
      </Row>
      <Form.List
        name="project_years"
        initialValue={init_project_years}
      >
        {(fields, { add, remove }, { errors }) => (
          <Row gutter={[10, 16]} align="top">
            {fields.map(({ key, name, ...restField }, index) => {
              const { adward_attached, project_attached } = project_years[index] || {}
              const refsID = uuidList[index]
              return (
                <React.Fragment key={key}>
                  <Col className="text-center pt-1" span={formListSpans[0]}>
                    {index + 1}
                    <Form.Item
                      hidden
                      name={[name, "excellentprojectmanager"]} initialValue={mID}
                      noStyle>
                      <Input type="text1"></Input>
                    </Form.Item>
                    <Form.Item
                      hidden
                      name={[name, "refs_id"]}
                      initialValue={refsID}>
                      <Input readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={formListSpans[1]}>
                    <Row gutter={[6, 6]} align="middle">
                      <Col span={formListSpansSub[0]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "name"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpansSub[1]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "area"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpansSub[2]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "price"]}
                        >
                          <InputNumber />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpansSub[3]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "start_finish"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpansSub[4]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "price_this_year"]}
                        >
                          <InputNumber onChange={onThisYearPriceChange} />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpansSub[5]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          name={[name, "honor"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span={formListSpansSub[6]}>
                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          rules={[mustRequiredRule]}
                          name={[name, "safe"]}
                        >
                          <Input type="text1" />
                        </Form.Item>
                      </Col>
                      <Col span="5">
                        <ProjectQuanlityAttached
                          title="获奖证明文件"
                          refs_id={refsID}
                          id={mID}
                          model_type={2}
                          restField={restField}
                          requiredRule={{ required: false }}
                          attached={adward_attached}
                        />
                      </Col>
                      <Col span="5">
                        <ProjectQuanlityAttached
                          title="工程证明文件"
                          refs_id={refsID}
                          id={mID}
                          model_type={3}
                          restField={restField}
                          requiredRule={mustRequiredRule}
                          attached={project_attached}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="pt-1" span={formListSpans[8]}>
                    {(
                      <MinusCircleOutlined
                        className="dynamic-delete-button "
                        onClick={() => {
                          const newList = [...uuidList]
                          newList.splice(name, 1)
                          setUuidList(newList)
                          remove(name)
                        }}
                      />
                    )}
                  </Col>
                </React.Fragment >
              )
            })}

            <Col span={23}>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add()
                    setUuidList([...uuidList, getUuid()])
                  }}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}
                >
                  增加工程
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </Col>

          </Row>
        )}
      </Form.List>
      <Row className="mb-10">
        <Col>
          <Space size={"large"}>
            <span>提示: {year}年完成工作量总计: <b>{totalThisYearPrice}</b> 万元</span>
            {totalThisYearPrice >= 500 ? (<Tag color="green">&gt;=500万</Tag>) : (<Tag color="gold">不足500万</Tag>)}

          </Space>
        </Col>
      </Row>
    </>
  )
}
export default ProjectQuanlity
