import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, Input, Row } from "antd";
import React, { useState } from "react";
import dayjs from 'dayjs';
import { getUuid } from "../container/utils";
import SubFormUpload from "./SubFormUpload";

const SuccessfulEntrepreneurAdward = ({ id, adwards, requiredRule, listName, listTitle, model_type, tips, objName, updateFun }) => {

  const [uuidList, setUuidList] = useState(adwards.map(item => item.refs_id))
  let init_ministry_awards = adwards
  if (!adwards || (adwards && adwards.length === 0)) {
    init_ministry_awards = null
  }
  const formListSpans = listName === "quality_awards" ?
    [2, 4, 4, 4, 4, 4, 2] :
    [2, 6, 6, 4, 4, 2]
  const colNameList = listName === "quality_awards" ?
    ["项目名称", "奖项名称", "发文单位", "发文时间", "获奖证明文件", " "] :
    ["奖项名称", "发文单位", "发文时间", "获奖证明文件", " "]
  const componentNames = listName === "quality_awards" ?
    ["project_name", "name", "issuing_unit", "issuing_date", "attached", " "] :
    ["name", "issuing_unit", "issuing_date", "attached", " "]

  return (
    <>
      <Divider className="pt-7" orientation="left">{listTitle}</Divider>
      <Row className="mb-3" gutter={[16, 4]}>
        <Col className="text-center" span={formListSpans[0]}>序号</Col>
        {
          colNameList.map((item, index) => {
            return (
              <Col span={formListSpans[index + 1]} key={index}>{item}</Col>
            )
          })
        }
      </Row>
      <Form.List
        name={listName}
        initialValue={init_ministry_awards}
      >
        {(fields, { add, remove }, { errors }) => (
          <Row gutter={[10, 16]} align={"top"}>
            {fields.map(({ key, name, ...restField }, index) => {
              const { attched } = adwards[index] || {}
              const mID = uuidList[index]
              const fileName = attched?.split("/").pop()
              const fileList = attched ? [{
                uid: mID,
                url: attched,
                name: decodeURIComponent(fileName),
                status: "done",
              }] : null
              return (
                <React.Fragment key={key}>
                  <Col className="text-center pt-1" span={formListSpans[0]}>
                    {index + 1}
                  </Col>
                  {componentNames.map((item, index) => {
                    if (item === "name" || item === "project_name" || item === "issuing_unit") {
                      return (
                        <Col key={index} span={formListSpans[index + 1]}>
                          <Form.Item
                            style={{ margin: 0 }}
                            {...restField}
                            rules={[requiredRule]}
                            name={[name, item]}
                          >
                            <Input type="text1" />
                          </Form.Item>
                        </Col>
                      )
                    }
                    else if (item === "issuing_date") {
                      return (
                        <Col key={index} span={formListSpans[index + 1]}>
                          <Form.Item
                            name={[name, "issuing_date"]}
                            style={{ margin: 0 }}
                            rules={[requiredRule]}
                            getValueFromEvent={(...[, dateString]) => dateString}
                            getValueProps={(value) => ({
                              value: value ? dayjs(value, "YYYY-MM-DD") : "",
                            })}
                          >
                            <DatePicker format={"YYYY-MM-DD"} className="w-full" />
                          </Form.Item>
                        </Col>
                      )
                    } else if (item === "attached") {
                      return (
                        <Col key={index} span={formListSpans[index + 1]}>

                          <SubFormUpload
                            originalFileList={fileList}
                            refs_id={mID}
                            id={id}
                            restField={restField}
                            requiredRule={requiredRule}
                            model_type={model_type}
                            updateFun={updateFun}
                          />

                        </Col>
                      )
                    } else {
                      return (
                        <Col key={index} span={formListSpans[index + 1]} className="pt-1">
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button "
                              onClick={() => {
                                const newList = [...uuidList]
                                newList.splice(name, 1)
                                setUuidList(newList)
                                remove(name)
                              }}
                            />
                          ) : null}
                          <Form.Item
                            hidden
                            name={[name, "refs_id"]}
                            initialValue={mID}>
                            <Input readOnly />
                          </Form.Item>
                          <Form.Item
                            hidden
                            name={[name, objName]}
                            initialValue={id}>
                            <Input readOnly />
                          </Form.Item>
                        </Col>)

                    }
                  })}

                </React.Fragment >
              )
            })}

            <Col span={23}>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add()
                    setUuidList([...uuidList, getUuid()])
                  }}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}
                >
                  增加奖项
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </Col>

          </Row>
        )}
      </Form.List >
      {tips && (
        <Col span={24}>
          <b>注：{tips}</b>
        </Col>
      )}
    </>
  )
}
export default SuccessfulEntrepreneurAdward
