import { Table, Space, Button, Tag, message, Popconfirm } from "antd";
import React from "react";
import { BASE_URL, useGetSuccessfulEntrepreneurQuery, useGetEvaluateplanQuery, useAddSuccessfulEntrepreneurMutation, useDeleteSuccessfulEntrepreneurMutation } from "../../features/api/userAPI";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FormOutlined, ReloadOutlined, } from "@ant-design/icons";
import { load } from "react-cookies";
import ContentWrap from "../../components/ContentHead";

const SuccessfulEntrepreneur = () => {
  const [deleteSuccessfulEntrepreneur, { isLoading: deleteIsLoading }] = useDeleteSuccessfulEntrepreneurMutation()
  const columns = [
    { title: "申请年份", dataIndex: "year", key: "year" },
    {
      title: "姓名", key: "name", render: (_, { name }) => {
        return name || "-"
      }
    },
    { title: "联系人", dataIndex: "contract", key: "contract" },
    { title: "联系电话", dataIndex: "contract_phone", key: "contract_phone" },
    {
      title: "结束日期", key: "end_date", render: (_, { year }) => {
        return <div>{rst?.end_date}</div>
      }
    },
    {
      title: "状态", key: "status", render: (_, { status, status_display, year }) => {
        if (rst?.year !== year) {
          return <Tag>已结束</Tag>
        } else if (!isEventStart) {
          return <Tag>{rst?.status}</Tag>
        } else {
          if (status === 0) {
            return <Tag color="processing">{status_display}</Tag>
          } else if (status === 1) {
            return <Tag color="success">{status_display}</Tag>
          } else {
            return <Tag>{status_display}</Tag>
          }
        }
      }
    },
    {
      title: "操作", key: "action", render: (_, { name, status, id, year }) => {
        if (status === 0 && isEventStart && rst?.year === year) {
          return (
            <Space>
              <Link to={`change/${id}`} state={year}>
                修改
              </Link>
              <Popconfirm
                title="删除后无法恢复"
                description="您确定要删除数据吗? "
                onConfirm={() => {
                  deleteSuccessfulEntrepreneur({ id })
                }}
              >
                <Link >
                  删除
                </Link>
              </Popconfirm>
            </Space>
          )
        } else if (status === 1) {
          return (
            <Space>
              <Link onClick={async () => {
                const downloadUrl = `${BASE_URL}/api/successfulentrepreneur/${id}/pdf/`
                const userInfo = load("userInfo")
                const token = userInfo?.token
                const result = await fetch(downloadUrl, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                });
                const blob = await result.blob();
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = `优秀企业家申请表-${name}.pdf`
                link.href = href
                link.click()
                URL.revokeObjectURL(href);
              }}
                target="_self"
                rel="noreferrer">
                下载申请表
              </Link>

            </Space>
          )

        }
      }
    },
  ]

  const [addSuccessfulEntrepreneur, { isLoading: addIsLoading }] = useAddSuccessfulEntrepreneurMutation()

  const { data: evaluatePlanData, isLoading: evaluatePlanDataLoading, isError, error, refetch } = useGetEvaluateplanQuery({ type: 2 })
  const rst = evaluatePlanData?.results[0]
  const { data, isLoading } = useGetSuccessfulEntrepreneurQuery()
  const errorStatus = error?.status
  const navigate = useNavigate()
  const isEventStart = rst?.status === "进行中.."
  const showRefresh = rst?.status !== "进行中.."
  if (isError && errorStatus === 401) {
    return (
      <Navigate to='/login' replace />
    )
  } else {
    const dataSource = data?.results
    return (
      <ContentWrap breadItems={[{ title: "评优评先" }, { title: "优秀企业家" }]}>
        <Space size={"middle"} className="mb-4">
          {isEventStart &&
            <Button onClick={() => {
              const formData = new FormData()
              formData.append("year", rst.year)
              addSuccessfulEntrepreneur({ formData }).then((rst) => {
                console.log("@103", rst);
                const _id = rst?.data?.id
                if (_id) {
                  navigate(`/successfulentrepreneur/change/${_id}`)
                } else {
                  message.error("添加失败")
                }
              })
            }} type="primary" icon={<FormOutlined />} size="middle"
              loading={addIsLoading || deleteIsLoading}
            >
              新增申请
            </Button>}
          {showRefresh &&
            <Button loading={evaluatePlanDataLoading} onClick={() => refetch()} icon={<ReloadOutlined />} size="middle">
              未开始
            </Button>
          }
        </Space>
        <Table
          loading={isLoading}
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
        />
      </ContentWrap>
    )
  }
}

export default SuccessfulEntrepreneur
