import { Input, Form, message } from "antd"
import FormItem from "antd/es/form/FormItem"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import {
  BASE_URL,
  useUserLoginMutation,
} from "../features/api/userAPI"
import { setUserToken, cleanupUserToken } from "../features/user/userSlice"
import { remove, save } from "react-cookies"
import { SESSION_LOGIN_TIME, SESSION_USER_INFO } from "./RequireUser"
import { LockOutlined, UserOutlined } from "@ant-design/icons"

const Login = () => {

  const [loginStatus, setLoginStatus] = useState('')
  const [error, setError] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userLogin,] = useUserLoginMutation()

  const [form] = Form.useForm()

  const onFinish = async (value) => {
    remove(SESSION_USER_INFO)
    remove(SESSION_LOGIN_TIME)
    dispatch(cleanupUserToken())
    const alldata = await userLogin(value)
    const { data, error } = alldata
    if (error) {
      setError("用户名或密码错误")
      setLoginStatus("error")
    } else {
      setLoginStatus("")
      setError("")
      const { username } = form.getFieldValue()
      const { token } = data
      save(SESSION_USER_INFO, { username, token })
      save(SESSION_LOGIN_TIME, new Date().getTime())
      await dispatch(setUserToken({ token, username }))
      navigate("/", { replace: true })
      message.success("登录成功！")
    }
  }
  return (
    <div
      className="h-screen flex flex-col bg-cover "
      style={{
        backgroundImage: `url(${BASE_URL}/static/imgs/login-bg.png)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    >

      <div
        className="mt-auto mb-auto mr-[5%] xl:mr-[10%] 2xl:mr-[20%] ml-auto w-[460px] h-[457.89px] "
      >
        <div className="w-full h-full mt-[20px]"
          style={{
            backgroundImage: `url(${BASE_URL}/static/imgs/login-form-bg.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}>
          <div className="mt-[54px] ml-[58px] w-[324px]">

            <div className="text-blue-600 text-2xl font-semibold font-['PingFang SC'] pt-[54px]">会员管理服务平台</div>
            <div className="text-blue-600 text-sm font-normal font-['PingFang SC']">欢迎您！</div>
            <Form
              form={form}
              name="login"
              layout="vertical"
              onFinish={onFinish}
            >
              <FormItem
                className="mt-[51px] mb-0"
                validateStatus={loginStatus}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "请输入统一社会信用代码"
                  }
                ]}>
                <Input
                  prefix={<UserOutlined className="text-blue-600" />}
                  autoComplete="username"
                  placeholder="请输入统一社会信用代码"
                  className="h-[46px] bg-white rounded-[5px] border border-blue-600"
                />
              </FormItem>
              <FormItem
                className="mb-0"
                validateStatus={loginStatus}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入密码"
                  }
                ]}>
                <Input.Password
                  prefix={<LockOutlined className="text-blue-600" />}
                  placeholder="请输入密码"
                  className="h-[46px] bg-white rounded-[5px] border border-blue-600 mt-[20px]"
                  autoComplete="current-password" />
              </FormItem>

              {error && (<div className="ml-6 text-red-500">{error}</div>)}

              <FormItem
                style={{ textAlign: "center" }}
                className="mt-[55px]"
              >
                <button
                  className="w-full h-[46px] bg-blue-600 rounded-[5px] text-white text-base font-normal font-['PingFang SC'] hover:bg-blue-800"
                  type="submit"
                >提交</button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
      <div className="mb-[22px] mx-auto flex gap-[22px]">
        <a href="https://www.zhikaiapp.com" target="_blank" className="text-white text-[13px] font-normal font-['PingFang SC']" rel="noreferrer">技术支持：衢州智开信息科技有限公司</a>
        <a href="http://beian.miit.gov.cn/" target="_blank" className="text-white text-[13px] font-normal font-['PingFang SC']" rel="noreferrer">浙ICP备14015071号</a>
      </div>
    </div >
  )
}
export default Login
