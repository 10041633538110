import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const userAdapter = createEntityAdapter()

const initialState = userAdapter.getInitialState({
  title: "衢州市建筑业行业协会会员管理服务平台",
  currentMenu: ["11", "1"],
  token: "",
  username: "",
  searchArea: [],
  breads: [
    { title: 'Home' },
    { title: 'Home2', path: '/home' }
  ]
})

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken(state, action) {
      const { token, username } = action.payload
      state.token = token
      state.username = username
    },
    cleanupUserToken(state) {
      state.token = ""
      state.username = ""
    },
    setMemberSearchArea(state, action) {
      const { area } = action.payload
      state.searchArea = area
    },
    setCurrentMenu(state, action) {
      const { menu } = action.payload
      state.currentMenu = [...menu]
    }
  },
})

export default userSlice.reducer

export const {
  setUserToken,
  cleanupUserToken,
  setMemberSearchArea,
  setCurrentMenu,
} = userSlice.actions
