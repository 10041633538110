import { Input, Space, Button, Form, message, Row, Col, Divider, Typography, Popconfirm, Select, Upload, DatePicker } from "antd";
import React, { useState } from "react";
import { useGetSuccessfulEntrepreneurDetailQuery, useUpdateSuccessfulEntrepreneurMutation, useGetMainprojectLevelTypeQuery, useUpdateSuccessfulEntrepreneurAttachedMutation, BASE_URL, useUpdateSuccessfulEntrepreneurAdwardMutation } from "../../features/api/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ContentWrap from "../../components/ContentHead";
import dayjs from 'dayjs';
import SuccessfulEntrepreneurAdward from "../../components/SuccessfulEntrepreneurAdward";
const { Title } = Typography
const SuccessfulEntrepreneurAdd = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, isLoading } = useGetSuccessfulEntrepreneurDetailQuery({ id })

  const [form] = useForm()
  const [updateSuccessfulEntrepreneurAttached] = useUpdateSuccessfulEntrepreneurAttachedMutation()
  const [updateSuccessfulEntrepreneur, { isLoading: updateIsLoading }] = useUpdateSuccessfulEntrepreneurMutation()
  const { data: mainProjectData, isLoading: getMainProjectLoading } = useGetMainprojectLevelTypeQuery()
  const options = mainProjectData?.results
  const [fileList, setFileList] = useState([])
  const [photoList, setPhotoList] = useState([])
  const STATUS_ATTACHED = 1
  const STATUS_PHOTO = 2
  const [requiredRule, setRequiredRule] = useState({ required: true, message: "这个字段是必须的" })

  const [requiredFileRule, setRequiredFileRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredPhotoRule, setRequiredPhotoRule] = useState({ required: true, message: "这个字段是必须的" })

  useEffect(() => {
    const attached = data?.attached
    const photo = data?.photo

    const defaultList = {
      uid: -1,
      status: "done"
    }

    if (attached) {
      setFileList([{
        name: data.attached_name,
        url: attached,
        ...defaultList
      }]);
    }
    if (photo) {
      setPhotoList([{
        name: data?.photo_name,
        url: photo,
        ...defaultList
      }]);
    }
  }, [data]);
  const breadItmes = [
    { title: "评优评先" },
    { title: "优秀企业家", path: "/successfulentrepreneur" },
    { title: "修改" }
  ]
  const setAttachedPhotoRequired = () => {
    if (fileList && fileList.length > 0) {
      setRequiredFileRule({ ...requiredFileRule, required: false })
    } else {
      setRequiredFileRule({ ...requiredFileRule, required: true })
    }
    if (photoList && photoList.length > 0) {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
    } else {
      setRequiredPhotoRule({ ...requiredPhotoRule, required: true })
    }
    console.log(requiredPhotoRule);
  }
  const navigate_list = () => {
    if (form.getFieldValue("status") !== 1) {
      setRequiredRule({ ...requiredRule, required: true })
      setAttachedPhotoRequired()
    } else {
      navigate("/successfulentrepreneur", { replace: true })
    }
  }

  const onFinish = (values) => {
    const payload = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    );
    console.log("@73", payload);
    updateSuccessfulEntrepreneur({ payload, id }).then((result) => {
      const _id = result?.data?.id
      if (_id) {
        message.success("保存成功")
        navigate_list()
      } else {
        message.error("登录超时，请重新登录")
      }
    })
  };
  const companyName = data?.company_name
  const title = "优秀企业家申请表"
  const mID = data?.id
  const memberID = data?.member
  const status = data?.status
  const addressWork = data?.company_address
  const certLevel = data?.cert_level
  const year = data?.year
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传图片
      </div>
    </div>
  )

  const addOrClearAttached = ({ formData, setFun }) => {
    updateSuccessfulEntrepreneurAttached({ payload: formData, id }).then((result) => {
      const rst = result?.data?.result
      const errorMessage = result?.data?.message
      const url = `${BASE_URL}${result?.data?.url}`
      const filename = result?.data?.filename
      if (rst !== "ok") {
        message.error(`保存失败: ${errorMessage}`)
      } else {
        if (result?.data?.url) {
          setFun([{
            uid: url,
            url: url,
            name: filename,
            status: 'done',
          }])
        } else {
          setFun(null)
        }
      }
    })
  }

  return (
    <ContentWrap breadItems={breadItmes}>
      {isLoading || getMainProjectLoading ?
        <LoadingOutlined /> :
        <Form
          form={form}
          className="mt-10 ml-20"
          onFinish={onFinish}
          scrollToFirstError
          style={{ maxWidth: 800 }}
        >
          <Form.Item hidden name={"year"} initialValue={year} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"id"} initialValue={mID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"member"} initialValue={memberID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"status"} initialValue={status} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Title className="text-center" level={3}>{title}</Title>
          <Divider></Divider>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name={"name"}
                label="姓名"
                initialValue={data?.name}
                rules={[{ required: true, message: "这个字段是必须的" }]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"sex"}
                label="性&emsp;&emsp;别"
                initialValue={data?.sex}
                rules={[requiredRule]}
              >
                <Select
                  options={[{ value: "男", label: "男" }, { value: "女", label: "女" }]}
                  placeholder="请选择性别"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.education}
                name={"education"}
                label="学历"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.birthday || ""}
                getValueFromEvent={(...[, dateString]) => dateString}
                getValueProps={(value) => ({
                  value: value ? dayjs(value, "YYYY-MM-DD") : "",
                })}
                name={"birthday"}
                label="出生年月"
                rules={[requiredRule]}
              >
                <DatePicker format={"YYYY-MM-DD"} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.position}
                name={"position"}
                label="职务"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.work_time}
                name={"work_time"}
                label="任职年限"
                rules={[requiredRule]}
                extra="以年为单位"
              >
                <Input addonAfter="年" type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.job_title}
                name={"job_title"}
                label="职称"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.political_background}
                name={"political_background"}
                label="政治面貌"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.tel}
                name={"tel"}
                label="申报人电话"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.phone}
                name={"phone"}
                label="申报人手机"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.id_card}
                name={"id_card"}
                label="身份证号码"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.application_type}
                name={"application_type"}
                label="企业分类"
                rules={[requiredRule]}
              >
                <Select
                  options={[
                    { label: "建筑业", value: "建筑业" },
                    { label: "交通", value: "交通" },
                    { label: "水利", value: "水利" },
                    { label: "电力", value: "电力" },
                  ]}
                  placeholder="请选择企业类型" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.contract}
                name={"contract"}
                label="联系人姓名"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={data?.contract_phone}
                name={"contract_phone"}
                label="联系人手机"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={companyName}
                name={"company_name"}
                label="单位名称"
                rules={[requiredRule]}
              >
                <Input readOnly type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"cert_level"}
                label="企业资质"
                initialValue={certLevel}
                rules={[requiredRule]}
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  optionFilterProp="children"
                  options={options}
                  placeholder="请选择企业资质"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={addressWork}
                name={"company_address"}
                label="单位地址"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={data?.moto_applicant}
                name={"moto_applicant"}
                label="申报人座右铭"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"work_resume"}
                initialValue={data?.work_resume}
                label="申报人工作简历">
                <TextArea autoSize={{ minRows: 4 }} ></TextArea>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={[requiredRule]}
                labelCol={{
                  span: 24,
                }}
                name={"performance_materials_this_year"}
                initialValue={data?.performance_materials_this_year}
                label={`申报人${year}年业绩材料（1000字以上）：`}>
                <TextArea autoSize={{ minRows: 6 }} ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="successfulentrepreneur"
            model_type={0}
            requiredRule={requiredRule}
            listTitle={`${data?.year - 1}年及${data?.year}年获得县级以上政府或部门奖项名称`}
            listName="ministry_awards"
            adwards={data?.ministry_awards}
            updateFun={useUpdateSuccessfulEntrepreneurAdwardMutation}
          />
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="successfulentrepreneur"
            model_type={1}
            requiredRule={requiredRule}
            listTitle={`${data?.year - 1}年及${data?.year}年质量安全奖项`}
            listName="quality_awards"
            adwards={data?.quality_awards}
            updateFun={useUpdateSuccessfulEntrepreneurAdwardMutation}
            tips="填写本年度获得的项目最高质量安全奖项"
          />
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="successfulentrepreneur"
            model_type={2}
            requiredRule={requiredRule}
            listTitle={`${data?.year - 1}年及${data?.year}年科技创新奖项`}
            listName="science_awards"
            adwards={data?.science_awards}
            updateFun={useUpdateSuccessfulEntrepreneurAdwardMutation}
          />
          <SuccessfulEntrepreneurAdward
            id={data?.id}
            objName="successfulentrepreneur"
            model_type={3}
            requiredRule={requiredRule}
            listTitle={`${data?.year - 1}年及${data?.year}年个人获得荣誉`}
            listName="personal_awards"
            adwards={data?.personal_awards}
            updateFun={useUpdateSuccessfulEntrepreneurAdwardMutation}
          />
          <Divider orientation="left">申报单位经营状况</Divider>
          <Row gutter={[16, 26]}>
            <Col span={2}><b>序号</b></Col>
            <Col span={6}><b>项目</b></Col>
            <Col span={2}><b>单位</b></Col>
            <Col span={5}><b>{year - 1}</b></Col>
            <Col span={5}><b>{year}</b></Col>
            <Col span={4}><b>备注</b></Col>
          </Row>
          <Row className="mt-6" gutter={[10, 0]}>
            {/* start loop */}
            <Col span={2}>1</Col>
            <Col span={6}>企业净资产</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item1_last_year}
                name={"item1_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item1_this_year}
                name={"item1_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item1_remark}
                name={"item1_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}

            {/* start loop */}
            <Col span={2}>2</Col>
            <Col span={6}>资产负债率</Col>
            <Col span={2}>%</Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item2_last_year}
                name={"item2_last_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                initialValue={data?.item2_this_year}
                name={"item2_this_year"}
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item2_remark}
                name={"item2_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>3</Col>
            <Col span={6}>建筑业总产值</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_last_year}
                name={"item3_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item3_this_year}
                name={"item3_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item3_remark}
                name={"item3_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>4</Col>
            <Col span={6}>其中：省外产值</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_last_year}
                name={"item4_last_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                initialValue={data?.item4_this_year}
                name={"item4_this_year"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                initialValue={data?.item4_remark}
                name={"item4_remark"}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>5</Col>
            <Col span={6}>营业收入</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_last_year"}
                initialValue={data?.item5_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item5_this_year"}
                initialValue={data?.item5_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item5_remark"}
                initialValue={data?.item5_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>6</Col>
            <Col span={6}>上缴税收</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_last_year"}
                initialValue={data?.item6_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item6_this_year"}
                initialValue={data?.item6_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item6_remark"}
                initialValue={data?.item6_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>7</Col>
            <Col span={6}>实现利润</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item7_last_year"}
                initialValue={data?.item7_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item7_this_year"}
                initialValue={data?.item7_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item7_remark"}
                initialValue={data?.item7_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>8</Col>
            <Col span={6}>科研经费投入</Col>
            <Col span={2}>万元</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item8_last_year"}
                initialValue={data?.item8_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item8_this_year"}
                initialValue={data?.item8_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item8_remark"}
                initialValue={data?.item8_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            {/* start loop */}
            <Col span={2}>9</Col>
            <Col span={6}>年末从业人员</Col>
            <Col span={2}>人</Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item9_last_year"}
                initialValue={data?.item9_last_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                rules={[requiredRule]}
                name={"item9_this_year"}
                initialValue={data?.item9_this_year}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={"item9_remark"}
                initialValue={data?.item9_remark}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            {/* end loop */}
            <Col span={24}>
              <b>注：1、资产负债率=负债总额/资产总额*100%</b>
            </Col>
          </Row>
          <Divider orientation="left"></Divider>

          <Form.Item
            rules={[requiredRule]}
            initialValue={data?.enterprise_opinion || "同意上报"}
            labelCol={{
              span: 24,
            }}
            name={"enterprise_opinion"}
            label={<b>企业意见</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            name="tempPhotoList"
            label="电子照片"
            extra="请使用1寸照片，宽：300px，高：450px"
            rules={[requiredPhotoRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              // console.log("@664", rst);
              return rst
            }}
          >
            <Upload
              accept="image/png,image/jpeg"
              customRequest={({ file }) => {
                const formData = new FormData()
                formData.append("attached", file)
                formData.append("year", year)
                formData.append("type", STATUS_PHOTO)
                addOrClearAttached({ formData, setFun: setPhotoList })
              }}
              onRemove={() => {
                const formData = new FormData()
                formData.append("attached", '')
                formData.append("year", year)
                formData.append("type", STATUS_PHOTO)
                addOrClearAttached({ formData, setFun: setPhotoList })
              }}
              fileList={photoList}
              maxCount={1}
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
            >
              {photoList && photoList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            name="tempUploadFile"
            label="申报材料"
            extra="市建筑业优秀企业家申报资料内容：1、企业三证（营业执照副本、资质证书副本、安全生产许可证）；2、申报企业近二年缴纳协会会费发票；3、申报人身份证正、反面。以上申报资料请汇总成一份PDF文件上传。"
            rules={[requiredFileRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              // console.log("@664", rst);
              return rst
            }}
          >
            <Upload
              accept="application/pdf"
              customRequest={({ file }) => {
                const formData = new FormData()
                formData.append("attached", file)
                formData.append("year", year)
                formData.append("type", STATUS_ATTACHED)
                addOrClearAttached({ formData, setFun: setFileList })
              }}
              onRemove={() => {
                const formData = new FormData()
                formData.append("attached", '')
                formData.append("year", year)
                formData.append("type", STATUS_ATTACHED)
                addOrClearAttached({ formData, setFun: setFileList })
              }}
              fileList={fileList}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          {data.status === 0 &&
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              className="text-center"
            >
              <Space size={"large"}>
                <Button size="large" onClick={() => {
                  setRequiredRule({ ...requiredRule, required: false })
                  setRequiredFileRule({ ...requiredFileRule, required: false })
                  setRequiredPhotoRule({ ...requiredPhotoRule, required: false })
                  form.setFieldValue("status", 0)
                  setTimeout(() => {
                    form.submit()
                  }, 100)
                }} loading={updateIsLoading} >
                  保存
                </Button>
                <Popconfirm
                  title="提醒"
                  description="提交后表单无法修改，您确认要提交吗？"
                  onConfirm={() => {
                    setAttachedPhotoRequired()
                    form.setFieldValue("status", 1)
                    setTimeout(() => {
                      form.submit()
                    }, 100)
                  }}
                  okText="确定"
                  cancelText="取消"
                >

                  <Button size="large" loading={updateIsLoading} type="primary">提交</Button>
                </Popconfirm>
              </Space>
            </Form.Item>
          }
        </Form >
      }
    </ContentWrap >
  )
}

export default SuccessfulEntrepreneurAdd
