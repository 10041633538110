import { Input, InputNumber, Select } from "antd"
import TextArea from "antd/es/input/TextArea"

const getRenderWidget = ({ widgetName, choices, valueDisplay, value }) => {
  if (widgetName === 'TextInput') {
    return <Input type="text1" className="ant-form-text" />
  } else if (widgetName === 'NumberInput') {
    return <InputNumber className="ant-form-text" />
  } else if (widgetName === 'Select') {
    return (<Select>
      {choices.map(([opID, opName]) => <Select.Option key={opID} value={opID}>{opName}</Select.Option>)}
    </Select>)
  } else if (widgetName === 'SelectMultiple') {
    return (<Select
      mode="multiple"
      filterOption={(input, option) => {
        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
      }}
    >
      {choices.map(({ id, value }) => <Select.Option key={id} value={id}>{value}</Select.Option>)}
    </Select>)
  } else if (widgetName === 'Textarea') {
    return (
      <TextArea />
    )
  }
  else {
    const rst = readonlyFields(valueDisplay)
    return <span className="ant-form-text">{rst}</span>
  }
}
const readonlyFields = (valueDisplay) => {
  if (typeof valueDisplay === "string") {
    return valueDisplay.split(',').map((value, index) => (
      <div className="flex items-center" style={{ height: 32 }} key={index}>
        {value}
      </div>
    ));
  } else {
    return (
      <div className="flex items-center" style={{ height: 32 }} >
        &nbsp;
      </div>
    )
  }
}
const getUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export { getRenderWidget, readonlyFields, getUuid }
