import React, { useEffect } from "react"
import { Navigate, Outlet, useNavigate } from "react-router-dom"
import { load } from 'react-cookies'
import { useGetUserQuery } from "../features/api/userAPI"
import { LoadingOutlined } from "@ant-design/icons"

export const SESSION_USER_INFO = "userInfo"
export const SESSION_LOGIN_TIME = "loginTime"
const RequireUser = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const loginTime = load(SESSION_LOGIN_TIME);
    const currentTime = new Date().getTime()
      ;
    const timeDifference = currentTime - parseInt(loginTime);

    // 设置登录过期时间为 30 分钟（以毫秒为单位）
    const expirationTime = 24 * 60 * 60 * 1000;
    // const expirationTime = 10 * 1000;
    if (timeDifference > expirationTime) {
      console.warn("timeout need relogin");
      navigate("/login", { replace: true })
    }
  }, [navigate])

  const { isLoading, error } = useGetUserQuery()
  const errorStatus = error?.status

  if (isLoading) {
    return <LoadingOutlined />
  } else if (errorStatus === 401) {
    return <Navigate to='/login' replace />

  } else {
    return <Outlet />
  }

}
export default RequireUser
